<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table-data
          :title="title"
          :default-table-headers="[]"
          :table-headers="tableHeaders"
          :form-scheme="formScheme"
          :form-default-values="formDefaultValues"
          :resource="resource"
          :expanded="expanded"
          :show-expand="$can('hosting.servers.view') ? showExpand : false"
          :single-expand="singleExpand"
          :item-class-fn="itemClassFn"
          @inputChange="handleChange"
          @dialogChange="handleDialog"
          @changeSelected="handleSelected"
          @currentItems="handleCurrentItems"
        >
          <template #[`item.name`]="{ item }">
            <div class="pt-2 pb-2">
              <div class="mb-1 text-no-wrap">
                {{ item.name }}
              </div>
              <div class="grey--text small">
                {{ item.notes || '' }}
              </div>
            </div>
          </template>
          <template #[`item.hp.name`]="{ item }">
            <div class="pt-2 pb-2">
              <div class="mb-1 text-no-wrap">
                {{ item.hp.name || '&mdash;' }}
              </div>
              <div class="grey--text small">
                {{ item.hp.description || '&mdash;' }}
              </div>
            </div>
          </template>
          <template #[`item.ip`]="{ item }">
            <v-chip-group
              active-class="primary--text"
              column
            >
              <v-chip
                v-for="(ip, key) in item.ip"
                :key="key"
                small
                class="ma-2 chip-with-copy"
                close
                close-icon="mdi-content-copy"
                @click:close="$copyToClipboard(ip)"
              >
                {{ ip }}
              </v-chip>
            </v-chip-group>
          </template>
          <template #[`item.proxy`]="{ item }">
            <div class="text-no-wrap">
              {{ item.proxy.name }}
            </div>
            <div class="grey--text small">
              {{ item.proxy.id ? `${item.proxy.type}://${item.proxy.host}:${item.proxy.port}` : '&mdash;' }}
            </div>
          </template>
          <template #action-buttons="{ item }">
            <table-btn
              :item="item"
              :tooltip-text="$t('Reload Apache, Nginx, PHP-FPM')"
              :disabled="item.importDomainsLoading || item.loading || !item.with_cp"
              :icon="`reload`"
              :can="`hosting.servers.update`"
              :fn="restartWeb"
            />
            <table-btn
              :item="item"
              :tooltip-text="$t('hosting.servers.table.btn.usersCredentials')"
              :icon="`account-key-outline`"
              :can="`credentials.view`"
              :fn="getUserCredentials"
            />
            <table-btn
              :item="item"
              :tooltip-text="$t('hosting.servers.table.btn.credentials')"
              :icon="`key-chain-variant`"
              :can="`hosting.servers.auth`"
              :fn="getCredentials"
            />
            <table-btn
              :item="item"
              :tooltip-text="$helper.isWin() === true ? $t('hosting.servers.table.btn.openWinSCP') : $t('hosting.servers.table.btn.copySFTPLink')"
              :icon="`link-variant`"
              :can="`hosting.servers.auth`"
              :fn="getSFTPCredentials"
            />
            <table-btn
              :item="item"
              :tooltip-text="$t('hosting.servers.table.btn.importDomains')"
              :disabled="item.importDomainsLoading || item.loading || !item.with_cp"
              :loading="item.importDomainsLoading || item.loading"
              :icon="`database-import-outline`"
              :can="`hosting.servers.import`"
              :fn="importDomains"
            />
            <table-btn
              v-if="item.with_cp"
              :item="item"
              :tooltip-text="$t('hosting.servers.table.btn.addDomain')"
              :disabled="item.loading"
              :icon="`plus`"
              :can="`hosting.servers.create`"
              :fn="addDomain"
            />
            <drop-menu
              v-if="item.with_cp"
              :actions-list="serverInfoMenu"
              :item="item"
              :loading="item.serverInfoLoading"
              :icon="`lifebuoy`"
            />
          </template>

          <template #item.data-table-expand="{ item, expand, isExpanded }">
            <v-btn
              v-if="!!item.with_cp"
              icon
              class="v-data-table__expand-icon"
              :class="{'v-data-table__expand-icon--active' : isExpanded}"
              @click="expand(!isExpanded)"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <component
                :is="detailsComponent"
                :params="item"
                :parent-search="getSearchQuery"
              />
            </td>
          </template>

          <template #[`item.team`]="{ item }">
            <div class="text-no-wrap">
              {{ item.team }}
            </div>
          </template>
        </table-data>
        <dialogs-server-domain
          :dialog="serverDomainDialog"
          :edited-item="serverDomainEditedItem"
          :action="'create'"
          @close="serverDomainDialogClose"
        />
      </v-col>
    </v-row>
    <dialog-credentials ref="dialogCredentials" />
    <dialog-confirmation ref="confirmationDialog" />
  </v-container>
</template>

<script>
import Vue from 'vue'
import countries from '@/countries'
import ServerDetails from './details/ServerDetails'
import DialogsServerDomain from './dialogs/ServerDomain'
// import DialogsServerCredentials from './dialogs/Credentials'

export default {
  name: 'Servers',
  components: {
    DialogsServerDomain
  },
  metaInfo () {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('hosting.servers.meta.title')} — %s`
    }
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    resource: 'hosting.servers',
    currentItems: [],

    showTeam: false,
    expanded: [],
    showExpand: true,
    singleExpand: true,
    detailsComponent: ServerDetails,
    //
    serverDomainDialog: false,
    serverDomainEditedItem: {},
    //
    editedItem: {},
    formDefaultValues: {
      ssh_port: 22
      // with_cp: false
    },
    //
    serverInfoMenu: []
  }),
  computed: {
    countriesList () {
      const data = []
      Object.keys(countries).forEach((key) => {
        const country = countries[key]
        data.push({ text: country.name, value: country.code })
      })
      return data
    },
    getLockedEntities() {
      return this.$store.getters['lockedEntities/getLockedEntities']
    },
    getSearchQuery() {
      return this.$store.getters[`__${this.resource}/options`].query
    }
  },
  watch: {
    async 'editedItem.with_cp' (val) {
      this.disableFields(val)
    }
  },
  async mounted () {
    this.title = this.$t('hosting.servers.title')

    let actionsColWight = 20
    actionsColWight += this.$can('hosting.servers.auth') ? 35 : 0
    actionsColWight += this.$can('hosting.servers.sync') ? 35 : 0
    actionsColWight += this.$can('hosting.servers.update') ? 35 : 0
    actionsColWight += this.$can('hosting.servers.remove') ? 35 : 0
    actionsColWight += this.$can('hosting.servers.create') ? 35 : 0

    this.tableHeaders = [
      {
        text: this.$t('hosting.servers.table.name'),
        align: 'start',
        value: 'name'
      },
      { text: this.$t('hosting.servers.table.providerName'), value: 'hp.name' },
      { text: this.$t('hosting.servers.table.ip'), value: 'ip' },
      { text: this.$t('hosting.servers.table.proxy'), value: 'proxy' },
      { text: this.$t('hosting.servers.table.domainsNum'), value: 'domainsNum', width: '100px', align: 'center' },
      { text: this.$t('hosting.servers.table.port'), value: 'port', width: '80px', align: 'center' },
      { text: this.$t('hosting.servers.table.country'), value: 'country', width: '100px', align: 'center' },
      {
        text: this.$t('tableData.creationDate'),
        align: 'center',
        value: 'createdAt',
        width: '125px'
      },
      {
        text: this.$t('tableData.actions'),
        align: 'center',
        value: 'actions',
        sortable: false,
        width: `${actionsColWight}px`
      }
    ]

    this.serverInfoMenu = [
      {
        icon: 'cpu-64-bit',
        text: this.$t('domains.table.actionsList.hostingServer.serverStatusInfo.cpuStatus'),
        fn: async (i) => {
          this.$set(i, 'serverInfoLoading', true)
          try {
            await this.getServerInfo(i._id, 'cpu')
          } catch (e) {
            console.log(e)
            throw e
          } finally {
            this.$set(i, 'serverInfoLoading', false)
          }
        }
      },
      {
        icon: 'memory',
        text: this.$t('domains.table.actionsList.hostingServer.serverStatusInfo.memoryStatus'),
        fn: async (i) => {
          this.$set(i, 'serverInfoLoading', true)
          try {
            await this.getServerInfo(i._id, 'memory')
          } catch (e) {
            console.log(e)
            throw e
          } finally {
            this.$set(i, 'serverInfoLoading', false)
          }
        }
      },
      {
        icon: 'harddisk',
        text: this.$t('domains.table.actionsList.hostingServer.serverStatusInfo.hardDiskStatus'),
        fn: async (i) => {
          this.$set(i, 'serverInfoLoading', true)
          try {
            await this.getServerInfo(i._id, 'disk')
          } catch (e) {
            console.log(e)
            throw e
          } finally {
            this.$set(i, 'serverInfoLoading', false)
          }
        }
      }
    ]

    // this.updateHostingServers()

    // if (this.$can('hosting.servers.auth')) {
    //   this.tableHeaders = this.$helper.addToArray(this.tableHeaders, {
    //     text: this.$t('hosting.servers.table.credentials'),
    //     value: 'credentials',
    //     sortable: false,
    //     width: '90px',
    //     align: 'center'
    //   }, 4)
    // }

    // if (this.$can('hosting.servers.sync')) {
    //   this.tableHeaders = this.$helper.addToArray(this.tableHeaders, {
    //     text: this.$t('hosting.servers.table.sync'),
    //     value: 'sync',
    //     sortable: false,
    //     width: '80px',
    //     align: 'center'
    //   }, 5)
    // }
    this.formScheme = {
      name: {
        type: 'text',
        label: this.$t('hosting.servers.tableForm.name'),
        createRules: 'req',
        updateRules: []
      },
      country: {
        type: 'autocomplete',
        label: this.$t('hosting.servers.tableForm.country'),
        items: this.countriesList,
        createRules: 'req',
        updateRules: []
      },
      ip: {
        type: 'combobox',
        smallChips: true,
        chips: true,
        multiple: true,
        items: this.editedItem.editedItem,
        label: this.$t('hosting.servers.tableForm.ip'),
        createRules: 'req',
        updateRules: []
      },
      with_cp: {
        type: 'switch',
        label: this.$t('hosting.servers.tableForm.withCp'),
        createRules: [],
        updateRules: []
      },
      port: {
        type: 'text',
        label: this.$t('hosting.servers.tableForm.port'),
        createRules: [],
        updateRules: [],
        md: 4
      },
      login: {
        type: 'text',
        label: this.$t('hosting.servers.tableForm.login'),
        createRules: [],
        updateRules: [],
        md: 4
      },
      password: {
        type: 'password',
        label: this.$t('hosting.servers.tableForm.password'),
        ref: 'password',
        data: {
          show: false
        },
        createRules: [],
        updateRules: [],
        md: 4
      },
      root_login: {
        type: 'text',
        label: this.$t('hosting.servers.tableForm.rootLogin'),
        createRules: [],
        updateRules: [],
        md: 4
      },
      root_password: {
        type: 'password',
        label: this.$t('hosting.servers.tableForm.rootPassword'),
        ref: 'password',
        data: {
          show: false
        },
        createRules: [],
        updateRules: [],
        md: 4
      },
      ssh_port: {
        type: 'text',
        label: this.$t('hosting.servers.tableForm.sshPort'),
        createRules: [],
        updateRules: [],
        md: 4
      },
      provider_id: {
        type: 'autocomplete',
        label: this.$t('hosting.servers.tableForm.provider'),
        items: await this.$store.dispatch('hostingProviders/getHostingProvidersList'),
        // createRules: 'req', // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
        createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
        updateRules: []
      },
      proxy_id: {
        type: 'autocomplete',
        label: this.$t('hosting.servers.tableForm.proxy'),
        items: await this.$store.dispatch('proxies/getProxiesList'),
        createRules: [],
        updateRules: [],
        md: 6
      },
      notes: {
        type: 'textarea',
        label: this.$t('hosting.servers.tableForm.notes'),
        'auto-grow': true,
        rows: 1,
        createRules: [],
        updateRules: [],
        md: 12
      }
    }
    if (this.$currentUser.role === process.env.VUE_APP_MAIN_ROLE || this.$currentUser.global === true) {
      this.tableHeaders = this.$helper.addToArray(this.tableHeaders, {
        text: this.$t('hosting.servers.table.team'),
        value: 'team'
      }, 1)
      this.formScheme = this.$helper.addToObject(this.formScheme, 'affiliate_team_id', {
        type: 'autocomplete',
        label: this.$t('hosting.servers.tableForm.teams'),
        items: await this.$store.dispatch('affiliatesTeams/all'),
        createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
        updateRules: [],
        md: 12
      }, 'notes')
    }
  },
  methods: {
    disableFields(val) {
      if (val === false) {
        Vue.set(this.formScheme.port, 'disabled', true)
        Vue.set(this.formScheme.login, 'disabled', true)
        Vue.set(this.formScheme.password, 'disabled', true)
        Vue.set(this.formScheme.root_login, 'disabled', true)
        Vue.set(this.formScheme.root_password, 'disabled', true)
        Vue.set(this.formScheme.ssh_port, 'disabled', true)
        Vue.set(this.formScheme.proxy_id, 'disabled', true)
      } else {
        Vue.set(this.formScheme.port, 'disabled', false)
        Vue.set(this.formScheme.login, 'disabled', false)
        Vue.set(this.formScheme.password, 'disabled', false)
        Vue.set(this.formScheme.root_login, 'disabled', false)
        Vue.set(this.formScheme.root_password, 'disabled', false)
        Vue.set(this.formScheme.ssh_port, 'disabled', false)
        Vue.set(this.formScheme.proxy_id, 'disabled', false)
      }
    },
    async getCredentials (item) {
      const result = await this.$store.dispatch('hostingServers/getHostingServerCredentials', { id: item.id })
      if (result) {
        await this.$store.dispatch('dialog/dialog', {
          show: true,
          title: this.$t('dialog.credentials'),
          contentType: 'code',
          content: JSON.stringify(result, null, 2),
          maxWidth: 500
        })
      }
    },
    async getUserCredentials (item) {
      const result = await this.$store.dispatch('credentials/getCredentials', item.id)
      if (result) {
        this.$refs.dialogCredentials.open(
          this.$t('dialog.credentials'),
          { entityId: item.id, entityType: 'hostingServer', affiliateTeamId: item.affiliate_team_id },
          {}
        )
      }
    },
    //   await this.$store.dispatch('dialog/dialog', {
    //     show: true,
    //     title: this.$t('dialog.credentials'),
    //     contentType: 'component',
    //     content: () => DialogsServerCredentials,
    //     data: {
    //       entityId: item._id,
    //       entity_type: 'hostingServer'
    //     },
    //     maxWidth: 800,
    //     maxHeight: 800
    //   })
    // },
    async getSFTPCredentials(item) {
      const result = await this.$store.dispatch('hostingServers/getHostingServerCredentials', { id: item.id })
      if (result) {
        if (this.$helper.isWin() === true) {
          await window.open(`winscp-${result.ssh.link}`, '_self')
        } else {
          await this.$copyToClipboard(`${result.ssh.link}`)
        }
      } else {
        await this.$copyToClipboard(`${result.ssh.link}`)
      }
    },
    async importDomains (item) {
      item.importDomainsLoading = true
      // this.$set(item, 'loading', true)
      try {
        const response = await this.$store.dispatch('hostingServers/importDomains', { id: item.id })
        if (response.success === true) {
          // Object.assign(item, response.result)
          // await this.$toast.success(this.$t('hosting.servers.import.success'))
        } else {
          await this.$toast.error(response.errors)
        }
      } catch (e) {
        console.log(e)
      } finally {
        // this.$set(item, 'loading', false)
      }
    },
    async addDomain (item) {
      this.serverDomainEditedItem = Object.assign({}, this.serverDomainEditedItem, { id: item.id })
      this.serverDomainDialog = true
    },
    serverDomainDialogClose () {
      this.serverDomainDialog = false
    },
    required (value) {
      if (value instanceof Array && value.length === 0) {
        return this.$t('hosting.servers.tableForm.validation.fieldIsRequired')
      }
      return !!value || this.$t('domains.tableForm.validation.fieldIsRequired')
    },
    // updateHostingServers() {
    //   this.$eventBus.$on('updateHostingServers', (data) => {
    //     this.$store.commit('__hosting.servers/saveItem', data, { root: true })
    //   })
    // },
    handleCurrentItems(items) {
      if (items.length > 0) {
        this.currentItems = items
      }
    },
    handleDialog (event) {
      if (event.dialog === true) {
        // this.editedItem = event.item
        this.editedItem.ssh_port = this.editedItem.ssh_port || 22
      }
    },
    handleSelected (selected) {
      this.selected = selected
    },
    handleChange (changes) {
      this.editedItem = changes
    },
    itemClassFn(item) {
      let classes
      if (this.getLockedEntities.indexOf(item._id) > -1) {
        classes += ' blue-grey lighten-5 loading-background '
      }
      return classes
    },
    async getServerInfo(id, what) {
      let fn
      let title
      switch (what) {
        case 'cpu':
          fn = 'getServerCpuStatus'
          title = 'Server CPU Status'
          break
        case 'memory':
          fn = 'getServerMemoryStatus'
          title = 'Server Memory Status'
          break
        case 'disk':
          fn = 'getServerDiskStatus'
          title = 'Server Disk Status'
          break
      }
      const response = await this.$store.dispatch(`hostingServers/${fn}`, { id })
      if (response.success === true) {
        let maxWidth = 800
        let str = ''
        if (response.result instanceof Array) {
          response.result.map(row => {
            str += row.join('\t') + '\n'
            return row
          })
        } else {
          str = JSON.stringify(response.result, null, 2)
          maxWidth = 500
        }
        await this.$store.dispatch('dialog/dialog', {
          show: true,
          title,
          contentType: 'code',
          content: str,
          maxWidth,
          maxHeight: 600,
          withActionButtons: false
        })
      } else {
        await this.$toast.error(response.errors)
      }
    },
    async restartWeb(i) {
      if (
        await this.$refs.confirmationDialog.open(
          // Title
          'Reload web server configuration',
          // Desc
          `Are you sure you want to reload Apache, Nginx & FPM on «${i.name}»? ` +
          'Please note that this action may cause the web server to crash.',
          { color: 'red', width: 400 }
        )
      ) {
        const response = await this.$store.dispatch('hostingServers/restartWeb', i._id)
        if (response.success === true) {
          await this.$toast.success(this.$t('Web server configuration reloaded'))
        }
      }
    }
  }
}
</script>

<style lang="scss">

</style>
